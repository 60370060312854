import React from 'react';
import AccordionItem from '../accordion';
import '../../css/components/_accordian.scss';

class Faq extends React.Component {
  render () {
    return (
      <div>
        <h2 className={'mb-2 font-normal text-3xl leading-snug'}>{this.props.title}</h2>
        <ul {...{className: 'accordion-list'}}>
          {this.props.data.map((data, key) => {
            return (
              <li {...{className: 'accordion-list__item', key}}>
                <AccordionItem {...data} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}


export default Faq;
