import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { STRINGS } from '../../constants/strings';
import { URL } from '../../constants/urls';
import '../../css/components/_winston-form.scss';
import { validateEmail } from '../../utils/articleUtil';

const WinstonForm= (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [tnc, setTnC] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [overFifty, setOverFifty] = useState(''); 
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const refs = {
    firstName: useRef(null),
    lastName: useRef(null),
    email: useRef(null),
    tnc: useRef(null),
    marketing: useRef(null),
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setFirstName(params.firstName || '');
    setLastName(params.lastName || '');
    setEmailAddress(params.email || '');
  }, []);

  const onActionClick = async (action) => {
    switch (action) {
      case 'yes':
        if (validateForm()) {
          setErrors({});
          setLoading(true);
          const name = `${firstName} ${lastName}`;

          const tags = `winston,winston_over_50_${overFifty}`; 

          fetch(`${URL.SERVER}/subscribe?name=${name}&email=${emailAddress}&marketing=${marketing}&website=${STRINGS.SUBSCRIPTION_TAG}&tag=${tags}&list=WINSTON`)
            .then(resp => resp.json())
            .then(res => {
              setLoading(false);
              setFirstName('');
              setLastName('');
              setEmailAddress('');
              setTnC(false);
              setMarketing(false);
              navigate(`/thankyou?returnUrl=/insurance/life-insurance/winston-over-50s-life-insurance`);
            });
        }
        break;
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let firstErrorField = null;

    if (!firstName.trim()) {
      newErrors.firstName = 'Please enter first name';
      if (!firstErrorField) firstErrorField = refs.firstName.current;
    }
    if (!lastName.trim()) {
      newErrors.lastName = 'Please enter last name';
      if (!firstErrorField) firstErrorField = refs.lastName.current;
    }
    if (!emailAddress || !validateEmail(emailAddress)) {
      newErrors.email = 'Please enter a valid email address';
      if (!firstErrorField) firstErrorField = refs.email.current;
    }
    if (!overFifty) {
      newErrors.overFifty = 'Please select if you are over 50';
    }
    if (!tnc) {
      newErrors.tnc = 'Please accept Terms and Privacy Policy';
      if (!firstErrorField) firstErrorField = refs.tnc.current;
    }
    if (!marketing) {
      newErrors.marketing = 'To participate in the prize draw we need your consent to contact you';
      if (!firstErrorField) firstErrorField = refs.marketing.current;
    }

    setErrors(newErrors);
    if (firstErrorField && typeof firstErrorField.scrollIntoView === 'function') {
      firstErrorField.scrollIntoView({ behavior: 'smooth' });
      firstErrorField.focus();
    }

    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="winston-form">
      <div className="container">
        <h1>WIN! A £50 Amazon voucher! Enter now!</h1>
        <div className="input-container">
          <label htmlFor="firstName">First name</label>
          <input
            id="firstName"
            type="text"
            placeholder="Enter your first name"
            value={firstName}
            onChange={(event) => {
              setErrors({});
              setFirstName(event.target.value);
            }}
            ref={refs.firstName}
          />
          {errors.firstName && <div className="text-red">{errors.firstName}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="lastName">Last name</label>
          <input
            id="lastName"
            type="text"
            placeholder="Enter your last name"
            value={lastName}
            onChange={(event) => {
              setErrors({});
              setLastName(event.target.value);
            }}
            ref={refs.lastName}
          />
          {errors.lastName && <div className="text-red">{errors.lastName}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="email">Email address</label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email address"
            value={emailAddress}
            onChange={(event) => {
              setErrors({});
              setEmailAddress(event.target.value);
            }}
            ref={refs.email}
          />
          {errors.email && <div className="text-red">{errors.email}</div>}
        </div>
        <div className="input-container">
          <label>Are you over 50?</label>
          <div className={'radio-container'}>
          <div className={'checkbox-container'}>
              <input
                type="radio"
                id="overFiftyYes"
                name="overFifty"
                value="yes"
                onChange={() => setOverFifty('yes')}
              />
              <label htmlFor="overFiftyYes">Yes</label>
            </div>
            <div className={'radio-container'}>
              <input
                type="radio"
                id="overFiftyNo"
                name="overFifty"
                value="no"
                onChange={() => setOverFifty('no')}
              />
              <label htmlFor="overFiftyNo">No</label>
            </div>
          </div>
        </div>
        {errors.overFifty && <div className="text-red">{errors.overFifty}</div>}
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="tnc"
            checked={tnc}
            onChange={() => {
              setErrors({});
              setTnC(!tnc);
            }}
            ref={refs.tnc}
          />
          <label htmlFor="tnc">I have read and accept the <a className="cursor-pointer underline" href="/winston-terms" target="_blank">Privacy Policy and Terms & Conditions</a>.</label>
        </div>
        {errors.tnc && <div className="text-red">{errors.tnc}</div>}
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="marketing"
            checked={marketing}
            onChange={() => {
              setErrors({});
              setMarketing(!marketing);
            }}
            ref={refs.marketing}
          />
          <label htmlFor="marketing">I’m happy for AgeGroup and Winston to send me selected marketing information which may be of interest to me.</label>
        </div>
        {errors.marketing && <div className="text-red">{errors.marketing}</div>}
        <div className="action-container mt-3">
          <button
            onClick={() => onActionClick('yes')}
            type="button"
            className="bg-red py-4 px-12 text-white rounded-[19px]"
          >
            SUBMIT MY ENTRY {loading && <img alt="loading" src="/images/loading.svg" style={{ marginLeft: '5px' }} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WinstonForm;
